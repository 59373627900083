const GlobalConst = {
  // qrcode settings
  qrHeadVersion: '0001',
  qrSize: 262,
  qrErrorLength: 1000,
  
  // init forms
  initForms: {
    select1: true,
    select2: false,
    select3: false,
    select4: false,
    select5: false,
  },
  
  // page titles
  titles: [
    {
      id: 1,
      name: '申請内容入力',
      leadText: '申請内容を選択し「次へ」を押してください（複数可）',
    },
    {
      id: 2,
      name: '住民異動届',
      leadText: '以下を入力してください',
    },
    {
      id: 3,
      name: '住民票写し等',
      leadText: '以下を入力してください',
    },
    {
      id: 4,
      name: '戸籍証明書等',
      leadText: '以下を入力してください',
    },
    {
      id: 5,
      name: '印鑑登録証明書発行',
      leadText: '以下を入力してください',
    },
  ]
};

export default GlobalConst;