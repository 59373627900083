<template>
  <div>
    <!-- page form -->
    <div v-show="page =='form'">
      <template v-if="isFinishGetCSV">
        <template v-for="(item,index) in formsShow">
          <div
            :key="index"
            class="main-page"
          >
            <h2 class="page-title">
              {{ titles[item-1].name }}
            </h2>
            <p :class="'debug '+'is-'+nodeEnv">
              Form ID:{{ item }}
            </p>
            <div class="page-content">
              <div
                v-if="isFinishGetCSV"
                class="list-instructions"
              >
                <ul>
                  <li>
                    <p class="text-jp">
                      {{ titles[item-1].leadText }}
                    </p>
                  </li>
                </ul>
                <p
                  v-show="!isValid() && isConfirmed && index == 0"
                  class="alert-text text-left"
                >
                  <span class="jp">エラーがあります。以下のエラー箇所をご確認ください。</span>
                </p>
              </div>
              <template v-for="(childItem, childIndex) in values">
                <section
                  v-if="childItem.fieldID == item"
                  v-show="handleShowHideGroup(childItem.groupFields)"
                  :key="childIndex"
                  class="section-category"
                >
                  <h2 class="title-item green">
                    <span class="jp">{{ childItem.item }}<span
                      v-if="childItem.groupFields[0].required"
                      class="small"
                    >（必須項目）</span></span>
                  </h2>

                  <div class="content">
                    <template v-for="(groupItem, groupIndex) in childItem.groupFields">
                      <div
                        :key="groupIndex"
                        :class="{'intended-use': (groupItem.type.startsWith('Text') && groupItem.other == 'Short')}"
                      >
                        <p :class="'debug '+'is-'+nodeEnv">
                          {{ childIndex + ' : ' + groupItem.noId + ' : ' + screenData['field_'+groupItem.noId] }}
                        </p>
                        <div
                          v-show="handleShowHideItem(groupItem.noId, groupItem.format)"
                          class="row"
                          :class="{'d-flex has-dash': groupItem.format == 'Text（Number input）' && groupItem.inputExample.length == 3 }"
                        >
                          <div
                            v-if="groupItem.format != 'Text（Number input）'"
                            class="item"
                          >
                            <label
                              v-if="groupItem.label != '-'"
                              class="text-label"
                            >
                              <span class="jp">{{ groupItem.label }}<span
                                v-if="groupItem.required"
                                class="small alert-text"
                              >（必須項目）</span></span>
                            </label>
                            <p
                              v-if="groupItem.descUp != '' && groupItem.descUp != '-'"
                              class="desc-up"
                            >
                              {{ groupItem.descUp }}<span
                                v-if="groupItem.required && groupItem.label == '-'"
                                class="small alert-text"
                              >（必須項目）</span>
                            </p>
                            <div
                              v-if="groupItem.type == 'Textbox'"
                              class="d-flex flex-middle"
                            >
                              <input
                                v-if="groupItem.format == 'Number（Natural）'"
                                :id="findID(groupItem.label, groupItem.noId)"
                                v-model="screenData['field_'+groupItem.noId]"
                                type="number"
                                autocomplete="off"
                                :name="'field_'+groupItem.noId"
                                :tabindex="parseInt(groupItem.noId,10)"
                                :readonly="groupItem.other.startsWith('Readonly')"
                                :placeholder="groupItem.inputExample != '' && groupItem.inputExample != '-' ? groupItem.inputExample[0] : ''"
                                @input="handleKanji(groupItem.label, groupItem.noId, groupItem.other); handleKana(groupItem.label, groupItem.noId, groupItem.other); handleInputNumber($event, groupItem.format, groupItem.noId); autoPostalCode(groupItem.noId, groupItem.format); checkForm();"
                              >
                              <input
                                v-else
                                :id="findID(groupItem.label, groupItem.noId)"
                                v-model="screenData['field_'+groupItem.noId]"
                                type="text"
                                autocomplete="off"
                                :name="'field_'+groupItem.noId"
                                :tabindex="parseInt(groupItem.noId,10)"
                                :readonly="groupItem.other.startsWith('Readonly')"
                                :placeholder="groupItem.inputExample != '' && groupItem.inputExample != '-' ? groupItem.inputExample[0] : ''"
                                @input="handleKanji(groupItem.label, groupItem.noId, groupItem.other); handleKana(groupItem.label, groupItem.noId, groupItem.other); handleInputNumber($event, groupItem.format, groupItem.noId); autoPostalCode(groupItem.noId, groupItem.format); checkForm();"
                              >
                              <p
                                v-if="groupItem.descDown != '' && groupItem.descDown != '-' && groupItem.descDown.indexOf('※') == -1"
                                class="desc-small"
                                v-html="groupItem.descDown"
                              />
                            </div>

                            <template v-if="groupItem.type == 'Pulldown'">
                              <CustomSelect
                                v-model="screenData['field_'+groupItem.noId]"
                                :tabindex="parseInt(groupItem.noId,10)"
                                :text-default="findDefault(groupItem.format, groupItem.inputExample)"
                                :selected-val="groupItem.inputExample[1] ? groupItem.inputExample[1] : ''"
                                :data="screenData['field_'+groupItem.noId]"
                                :options="findOptions(groupItem.format, groupItem.desc, groupItem.noId)"
                                @change="checkForm()"
                              />
                            </template>

                            <template v-if="groupItem.type == 'Date'">
                              <datepicker
                                v-model="screenData['field_'+groupItem.noId]"
                                format="yyyy年M月d日"
                                :tabindex="parseInt(groupItem.noId,10)"
                                :language="datePickerLang"
                                :disabled-dates="groupItem.format == 'YYYY年M月D日（Future）' ? datePickerDisabledDates : {}"
                                :placeholder="groupItem.inputExample[0]"
                                :disabled="groupItem.other.startsWith('Readonly')"
                                :class="groupItem.other.startsWith('Readonly') ? 'is-readonly' : ''"
                                @input="checkForm()"
                              />
                            </template>

                            <template v-if="groupItem.type == 'Checkbox'">
                              <template v-for="(descItem, descIndex) in groupItem.desc">
                                <div
                                  v-if="descItem != ''"
                                  :key="descIndex"
                                  class="item"
                                >
                                  <label class="item-checkbox no-padding-bottom">
                                    <input
                                      v-model="screenData['field_'+groupItem.noId+'_'+descIndex]"
                                      type="checkbox"
                                      :tabindex="parseInt(groupItem.noId,10)"
                                      :data-name="'field_'+groupItem.noId+'_'+descIndex"
                                      :disabled="groupItem.other.startsWith('Readonly')"
                                      @change="copyData(groupItem.noId,descIndex,groupItem.other); checkForm()"
                                    >
                                    <span class="text-label inline">
                                      <span class="jp">{{ descItem }}</span>
                                    </span>
                                  </label>
                                </div>
                              </template>
                            </template>
                          </div>

                          <template
                            v-for="(itemNumber, indexNumber) in groupItem.inputExample"
                            v-else
                          >
                            <div
                              :key="indexNumber"
                              class="item"
                            >
                              <input
                                v-model="screenData['field_'+groupItem.noId+'_'+indexNumber]"
                                type="number"
                                autocomplete="off"
                                :placeholder="itemNumber"
                                :tabindex="parseInt(groupItem.noId,10)"
                                :name="'field_'+groupItem.noId+'_'+indexNumber"
                                @input="handleInputNumber($event, groupItem.format, groupItem.noId+'_'+indexNumber); autoPostalCode(groupItem.noId, groupItem.format); checkForm()"
                              >
                            </div>
                          </template>
                          <ul
                            v-if="groupItem.type == 'Radiobutton'"
                            class="list-radio small-space"
                          >
                            <template v-for="(descItem, descIndex) in groupItem.desc">
                              <li
                                :key="descIndex"
                                class="item"
                              >
                                <label class="item-radio">
                                  <input
                                    v-model="screenData['field_'+groupItem.noId]"
                                    type="radio"
                                    :tabindex="parseInt(groupItem.noId,10)"
                                    :value="descItem"
                                    :name="'field_'+groupItem.noId"
                                    @change="checkForm()"
                                  >
                                  <span class="text-label">
                                    <span class="jp">{{ descItem }}</span>
                                  </span>
                                </label>
                              </li>
                            </template>
                          </ul>
                          <p
                            v-if="groupItem.descDown != '' && groupItem.descDown != '-' && groupItem.descDown.indexOf('※') > -1"
                            class="desc-down"
                            v-html="groupItem.descDown"
                          />
                          <!-- 【アプリケーション固有の設定】 -->
                          <p v-if="groupItem.noId == 255">
                            ※本人以外の方が身分証明書取りに来られた場合は、委任状も必要です。
                          </p>
                          <p v-if="groupItem.noId == 289">
                            ※印鑑登録と同時に印鑑証明書を発行する場合、登録番号は空欄で結構です。
                          </p>
                          <p v-if="groupItem.noId == 290">
                            ※印鑑登録証明書の発行には、印鑑登録証が必要です。印鑑登録証を紛失した場合、再度印鑑登録をする必要があります。
                          </p>


                        </div>
                        <p
                          v-if="handleShowHideItem(groupItem.noId, groupItem.format) && handleShowAlert(groupItem.error, groupItem.isError)"
                          class="alert-text"
                        >
                          <span
                            class="jp"
                            v-html="showTxtError(groupItem.error, groupItem.noId)"
                          />
                        </p>
                      </div>
                    </template>
                  </div>
                </section>
              </template>
              <div
                v-if="formsShow.length == index + 1"
                class="list-button"
              >
                <button
                  v-if="nodeEnv == 'development'"
                  type="button"
                  class="button trans"
                  @click="debugShow()"
                >
                  <span class="txt">デバッグ</span>
                </button>
                <button
                  tabindex="500"
                  type="button"
                  class="button trans"
                  @click="changeRouter('agreements')"
                >
                  <span class="txt">戻る</span>
                </button>
                <button
                  tabindex="501"
                  type="button"
                  class="button trans"
                  @click="next()"
                >
                  <span class="txt">次へ</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <div class="box-loading-from">
          <p class="txt-loading">
            データを読込中です。
          </p>
        </div>
      </template>
    </div>
    <!-- END: page form -->

    <!-- page comfirm -->
    <div v-show="page == 'confirm'">
      <template v-for="(item,index2) in formsShow">
        <div
          :key="index2+'k'"
          class="main-page"
        >
          <h2
            v-if="item == 1"
            class="page-title"
          >
            申請内容確認
          </h2>
          <h2
            v-else
            class="page-title"
          >
            {{ titles[item-1].name }}
          </h2>
          <div class="page-content">
            <div class="list-instructions">
              <ul>
                <li>
                  <p class="text-jp">
                    入力内容を確認して、一番下の「QRコード作成」を押してください。
                  </p>
                </li>
                <li>
                  <p class="text-jp">
                    修正する場合は、「戻る」をクリックしてください。
                  </p>
                </li>
              </ul>
            </div>
            <template v-for="(childItem, childIndex) in values">
              <section
                v-if="childItem.fieldID == item"
                v-show="handleShowHideGroup(childItem.groupFields)"
                :key="childIndex"
                class="section-category"
              >
                <h2 class="title-item green">
                  <span class="jp">{{ childItem.item }}</span>
                </h2>
                <div class="content">
                  <template v-for="(groupItem, groupIndex) in childItem.groupFields">
                    <!-- 【アプリケーション固有の設定】 -->
                    <div
                      v-show="isShowConfirm['field_'+groupItem.noId]"
                      v-if="groupItem.noId != 244 && groupItem.format != 'YYYY（元号YY）' && groupItem.format != 'MM' "
                      :key="groupIndex"
                      class="row"
                    >
                      <p :class="'debug '+'is-'+nodeEnv">
                        {{ groupItem.noId +' : ' + screenData['field_'+groupItem.noId] }}
                      </p>
                      <div class="item">
                        <label
                          v-if="groupItem.label != '-' && groupItem.label != ''"
                          class="text-label"
                        >
                          <span class="jp">{{ groupItem.format == 'DD' ? '生年月日' : groupItem.label }}</span>
                        </label>
                        <div
                          class="input-data"
                          :data-noid="groupItem.noId"
                          v-html="showData(groupItem.noId, groupItem.desc, groupItem.type, groupItem.format, groupItem.label)"
                        />
                      </div>
                    </div>
                  </template>
                </div>
              </section>
            </template>
            <div
              v-if="formsShow.length == index2 + 1"
              class="list-button"
            >
              <button
                v-if="nodeEnv == 'development'"
                type="button"
                class="button trans"
                @click="debugShow()"
              >
                <span class="txt">デバッグ</span>
              </button>
              <button
                type="button"
                class="button trans"
                @click="backToForm()"
              >
                <span class="txt">戻る</span>
              </button>
              <button
                type="button"
                class="button trans"
                @click="goToQRCode()"
              >
                <span class="txt">QRコード作成</span>
              </button>
            </div>
          </div>
        </div>
      </template>
    </div>
    <!-- END: comfirm page -->
  </div>
</template>

<style>
input[type=number] {
  ime-mode: inactive;
}
</style>

<script>
import GlobalConst from '../Constants.js';
import CustomSelect from '../components/CustomSelect.vue';
import Datepicker from 'vuejs-datepicker';
import { ja } from 'vuejs-datepicker/dist/locale';
import * as AutoKana from 'vanilla-autokana';
let autokanaFirst1 = {};
let autokanaLast1 = {};
export default {
  name: 'FormLayout',
  components: {
    CustomSelect,
    Datepicker,
  },
  data() {
    return {
      nodeEnv: 'production', // NODE_ENV
      page: 'form', // show current page

      titles: GlobalConst.titles, // Heading titles
      forms: {}, // List of forms selected by user
      values: [], // Object containing values of all fields
      csvValues: [], // Object containing values of raw CSV
      screenData: {}, // Object containing input values

      isConfirmed: false, // Show error title of page when user try to go next(Not on the time of validation)
      isShowConfirm: {},
      isFinishGetCSV: false,

      state: {
        date: new Date()
      },

      datePickerLang: ja, // for datepicker language
      datePickerDisabledDates: {}, // Keep disabled dates for datepicker
    };
  },
  computed: {
    formsShow() {
      let arr = [];
      for (let key in this.forms) {
        if (this.forms[key]) arr.push(parseInt(key.slice(6), 10));
      }
      return arr;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    //
    // SHOW OR HIDE
    //
    /**
     * Show or hide by group
     * 【アプリケーション固有の設定】
     */
    handleShowHideGroup: function (groupFields) {
      // form1
      if (groupFields[0].noId == 30) {
        if(this.screenData['field_18'] != '1．個人') return false;
      }
      if (groupFields[0].noId == 35
      || groupFields[0].noId == 42) {
        if(this.screenData['field_18'] != '2．法人') return false;
      }

      // form2
      if (groupFields[0].noId == 64) {
        if(this.screenData['field_50'] != '3．代理人') return false;
      }

      // form5
      if (groupFields[0].noId == 285) {
        if(this.screenData['field_284_0'] != true) return false;
      }
      if (groupFields[0].noId == 286) {
        if(this.screenData['field_284_1'] != true) return false;
      }
      if (groupFields[0].noId == 289) {
        if(this.screenData['field_284_2'] != true) return false;
      }

      return true;
    },
    /**
     * Show or hide input text, checkbox and pulldown (=except Text(Number Input)).
     * 【アプリケーション固有の設定】
     */
    handleShowHideItem: function (noId, format) {
      format == format;

      // form2
      // 1st
      if (noId == 106 || noId == 107) {
        if(this.screenData['field_47'] != '2．転居届（市内での異動）') return false;
      }
      if (noId == 109 || (noId >= 111 && noId <= 114)) {
        if(this.screenData['field_108'] != '2．その他') return false;
      }
      if (noId == 110) {
        if(this.screenData['field_109'] != '3．その他') return false;
      }
      // 2nd
      if (noId == 125 || noId == 126) {
        if(this.screenData['field_47'] != '2．転居届（市内での異動）') return false;
      }
      if (noId == 128 || (noId >= 130 && noId <= 133)) {
        if(this.screenData['field_127'] != '2．その他') return false;
      }
      if (noId == 129) {
        if(this.screenData['field_128'] != '3．その他') return false;
      }
      // 3rd
      if (noId == 144 || noId == 145) {
        if(this.screenData['field_47'] != '2．転居届（市内での異動）') return false;
      }
      if (noId == 147 || (noId >= 149 && noId <= 152)) {
        if(this.screenData['field_146'] != '2．その他') return false;
      }
      if (noId == 148) {
        if(this.screenData['field_147'] != '3．その他') return false;
      }
      // 4th
      if (noId == 163 || noId == 164) {
        if(this.screenData['field_47'] != '2．転居届（市内での異動）') return false;
      }
      if (noId == 166 || (noId >= 168 && noId <= 171)) {
        if(this.screenData['field_165'] != '2．その他') return false;
      }
      if (noId == 167) {
        if(this.screenData['field_166'] != '3．その他') return false;
      }
      // 5th
      if (noId == 182 || noId == 183) {
        if(this.screenData['field_47'] != '2．転居届（市内での異動）') return false;
      }
      if (noId == 185 || (noId >= 187 && noId <= 190)) {
        if(this.screenData['field_184'] != '2．その他') return false;
      }
      if (noId == 186) {
        if(this.screenData['field_185'] != '3．その他') return false;
      }

      // form3
      if (noId == 212) {
        if(this.screenData['field_211'] != '4．その他') return false;
      }
      if (noId == 214) {
        if(this.screenData['field_213'] != '4．その他') return false;
      }
      if (noId == 222) {
        if(!(this.screenData['field_221'] >= 1)) return false;
      }

      // form4
      if (noId == 240) {
        if(this.screenData['field_239'] != '6．その他') return false;
      }
      if (noId == 242 || noId == 243 || noId == 244 || noId == 245) {
        if(this.screenData['field_241'] != '4．相続') return false;
      }
      if (noId == 246) {
        if(this.screenData['field_241'] != '7．その他') return false;
      }
      if (noId == 258) {
        if(!(this.screenData['field_257'] >= 1)) return false;
      }
      if (noId == 259) {
        if(this.screenData['field_258'] != '4．その他') return false;
      }
      if (noId == 261) {
        if(!(this.screenData['field_260'] >= 1)) return false;
      }

      //form5
      if (noId == 287) {
        if(this.screenData['field_286'] != '4．その他') return false;
      }

      return true;
    },
    /**
     * Filter before show text error.
     */
    showTxtError: function (error, noId) {
      noId == noId;
      return error.replace(/__/g, '<br>');
    },
    /**
     * Handle show alert text.
     */
    handleShowAlert: function (error, isError) {
      if (error != '' && error != '-' && isError) {
        return true;
      }
      return false;
    },
    /**
     * Init id for input that needs transform to kana
     */
    findID: function (label, noId) {
      if (label == '姓（漢字）') {
        return 'last-name-' + noId;
      }
      if (label == '名（漢字）') {
        return 'first-name-' + noId;
      }
      if (label == '姓（カナ）' || label == '姓（かな）') {
        return 'last-name-kana-' + noId;
      }
      if (label == '名（カナ）' || label == '名（かな）') {
        return 'first-name-kana-' + noId;
      }
      return 'field-' + noId;
    },



    //
    // PAGE TRANSITIONS
    //
    /**
     * Go next page if there is no error
     */
    next: function () {
      this.isConfirmed = true;
      if (this.isValid()) {
        if(process.env.NODE_ENV == 'development') {
          console.log('[Go Confirm]');
          console.log(this.screenData);
        }
        this.page = 'confirm';
      }
      window.scrollTo(0, 0);
    },
    /**
     * Go back to form filling data.
     */
    backToForm() {
      window.scrollTo(0, 0);
      if(process.env.NODE_ENV == 'development') {
        console.log('[Go Back to Form]');
        console.log(this.screenData);
      }
      this.page = 'form';
    },
    /**
     * Go to QR code page.
     */
    goToQRCode: function () {
      this.$store.state.values = this.values;
      this.$store.state.csvValues = this.csvValues;
      this.$store.state.screenData = this.screenData;
      if(process.env.NODE_ENV == 'development') {
        console.log('[GO QR]');
        console.log(this.screenData);
      }
      this.$router.push({ name: 'qr-code' });
    },
    /**
     * Change router
     * @param {string} name - name of router to change
     */
    changeRouter: function (name) {
      this.$router.push({ name: name });
    },



    //
    // VALIDATIONS
    //
    isValid: function () {
        for (let i = 0; i < this.values.length; i++) {
          for (let j = 0; j < this.values[i].groupFields.length; j++) {
            if(this.values[i].groupFields[j].isError == true) {
              return false;
            }
          }
        }
      return true;
    },
    /**
     * validating form input
     */
    checkForm: function () {
      setTimeout(() => {
        for (let i = 0; i < this.values.length; i++) {
          for (let j = 0; j < this.values[i].groupFields.length; j++) {
            const noId = this.values[i].groupFields[j].noId;

            // some special orders
            //【アプリケーション固有の設定】
            if(noId == 68) {
              if(this.screenData['field_47'] == '1．転出届（市外への異動）') {
                this.values[i].groupFields[j].inputExample = new Array('例）新宿区西新宿');
              } else {
                this.values[i].groupFields[j].inputExample = new Array('例）野々市市三納');
              }
            }
            if(noId == 81) {
              if(this.screenData['field_47'] == '3．転入届（市外からの異動）') {
                this.values[i].groupFields[j].inputExample = new Array('例）新宿区西新宿');
              } else {
                this.values[i].groupFields[j].inputExample = new Array('例）野々市市三納');
              }
            }

            // invisible never be error
            if(!this.handleShowHideGroup(this.values[i].groupFields) || !this.handleShowHideItem(this.values[i].groupFields[j].noId,this.values[i].groupFields[j].format)) {
              this.values[i].groupFields[j].isError = false;
              continue;
            }

            // some special validations
            // 【アプリケーション固有の設定】ここから
            if(noId == 68 && this.screenData['field_47'] == '1．転出届（市外への異動）') {
              this.values[i].groupFields[j].error = '野々市市以外の住所を入力してください';
              let beforeID = parseInt(noId,10) - 1;
              if(
                document.getElementById('field-'+noId) &&
                document.getElementById('field-'+noId).value.indexOf('野々市市') != -1 &&
                this.screenData['field_'+beforeID] &&
                this.screenData['field_'+beforeID] == '石川県'
              ) {
                this.values[i].groupFields[j].isError = true;
              } else if(!this.screenData['field_' + noId]) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if(noId == 68 && (this.screenData['field_47'] == '2．転居届（市内での異動）'
            || this.screenData['field_47'] == '3．転入届（市外からの異動）')) {
              this.values[i].groupFields[j].error = '野々市市の住所を入力してください';
              let beforeID = parseInt(noId,10) - 1;
              if(
                document.getElementById('field-'+noId) &&
                document.getElementById('field-'+noId).value.indexOf('野々市市') != -1 &&
                this.screenData['field_'+beforeID] &&
                this.screenData['field_'+beforeID] == '石川県'
              ) {
                this.values[i].groupFields[j].isError = false;
              } else if(!this.screenData['field_' + noId]) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = true;
              }
            } else if(noId == 68) {
              this.values[i].groupFields[j].error = '入力してください';
              if(!this.screenData['field_' + noId]) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if(noId == 81 && this.screenData['field_47'] == '3．転入届（市外からの異動）') {
              this.values[i].groupFields[j].error = '野々市市以外の住所を入力してください';
              let beforeID = parseInt(noId,10) - 1;
              if(
                document.getElementById('field-'+noId) &&
                document.getElementById('field-'+noId).value.indexOf('野々市市') != -1 &&
                this.screenData['field_'+beforeID] &&
                this.screenData['field_'+beforeID] == '石川県'
              ) {
                this.values[i].groupFields[j].isError = true;
              } else if(!this.screenData['field_' + noId]) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if(noId == 81 && (this.screenData['field_47'] == '2．転居届（市内での異動）'
            || this.screenData['field_47'] == '1．転出届（市外への異動）')) {
              this.values[i].groupFields[j].error = '野々市市の住所を入力してください';
              let beforeID = parseInt(noId,10) - 1;
              if(
                document.getElementById('field-'+noId) &&
                document.getElementById('field-'+noId).value.indexOf('野々市市') != -1 &&
                this.screenData['field_'+beforeID] &&
                this.screenData['field_'+beforeID] == '石川県'
              ) {
                this.values[i].groupFields[j].isError = false;
              } else if(!this.screenData['field_' + noId]) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = true;
              }
            } else if(noId == 81) {
              this.values[i].groupFields[j].error = '入力してください';
              if(!this.screenData['field_' + noId]) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if ([107,126,145,164,183].includes(parseInt(noId,10))) {
              // 学年選択
              let beforeID = parseInt(noId,10) - 1;
              if(this.screenData['field_'+beforeID].trim() != ''
              && this.screenData['field_'+noId].trim() == '') {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if (
              [98,99,100,101,102,103,104,105,108].includes(parseInt(noId,10))
            ) {
              // Error check for '異動される方①' (The case they have '姓（漢字）')
              if (
                this.screenData['field_97'] &&
                this.screenData['field_97'].trim() != '' &&
                !this.screenData['field_' + noId]
              ) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if (
              [117,118,119,120,121,122,123,124,127].includes(parseInt(noId,10))
            ) {
              // Error check for '異動される方2' (The case they have '姓（漢字）')
              if (
                this.screenData['field_116'] &&
                this.screenData['field_116'].trim() != '' &&
                !this.screenData['field_' + noId]
              ) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if (
              [136,137,138,139,140,141,142,143,146].includes(parseInt(noId,10))
            ) {
              // Error check for '異動される方3' (The case they have '姓（漢字）')
              if (
                this.screenData['field_135'] &&
                this.screenData['field_135'].trim() != '' &&
                !this.screenData['field_' + noId]
              ) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if (
              [155,156,157,158,159,160,161,162,165].includes(parseInt(noId,10))
            ) {
              // Error check for '異動される方4' (The case they have '姓（漢字）')
              if (
                this.screenData['field_154'] &&
                this.screenData['field_154'].trim() != '' &&
                !this.screenData['field_' + noId]
              ) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if (
              [174,175,176,177,178,179,180,181,184].includes(parseInt(noId,10))
            ) {
              // Error check for '異動される方5' (The case they have '姓（漢字）')
              if (
                this.screenData['field_173'] &&
                this.screenData['field_173'].trim() != '' &&
                !this.screenData['field_' + noId]
              ) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if (noId == 213) {
              if(
                this.screenData['field_18'] == '2．法人' &&
                !this.screenData['field_'+noId]
              ) {
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].isError = false;
              }
            } else if(noId == 198) {
              let beforeID = parseInt(noId,10) - 1;
              if(
                document.getElementById('field-'+noId) &&
                document.getElementById('field-'+noId).value.indexOf('野々市市') != -1 &&
                this.screenData['field_'+beforeID] &&
                this.screenData['field_'+beforeID] == '石川県'
              ) {
                this.values[i].groupFields[j].isError = false;
              } else if(!this.screenData['field_' + noId]) {
                this.values[i].groupFields[j].error = '入力してください';
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].error = '野々市市の住所を入力してください';
                this.values[i].groupFields[j].isError = true;
              }

            } else if (
              [215,216,217,218,219,220,221].includes(parseInt(noId,10))
            ) {
              // Error check for No.215 - 221 (The sum have to be plus natural number)
              let sum = (isNaN(parseInt(this.screenData['field_215'],10)) ? 0 : parseInt(this.screenData['field_215'],10)) +
              (isNaN(parseInt(this.screenData['field_216'],10)) ? 0 : parseInt(this.screenData['field_216'],10)) +
              (isNaN(parseInt(this.screenData['field_217'],10)) ? 0 : parseInt(this.screenData['field_217'],10)) +
              (isNaN(parseInt(this.screenData['field_218'],10)) ? 0 : parseInt(this.screenData['field_218'],10)) +
              (isNaN(parseInt(this.screenData['field_219'],10)) ? 0 : parseInt(this.screenData['field_219'],10)) +
              (isNaN(parseInt(this.screenData['field_220'],10)) ? 0 : parseInt(this.screenData['field_220'],10)) +
              (isNaN(parseInt(this.screenData['field_221'],10)) ? 0 : parseInt(this.screenData['field_221'],10));

              if(sum < 1) this.values[i].groupFields[j].isError = true;
              else this.values[i].groupFields[j].isError = false;
            } else if (
              [247,248,249,250,251,252,253,254,255,256,257,260].includes(parseInt(noId,10))
            ) {
              // Error check for No.247 - 257,260 (The sum have to be plus natural number)
              let sum = (isNaN(parseInt(this.screenData['field_247'],10)) ? 0 : parseInt(this.screenData['field_247'],10)) +
              (isNaN(parseInt(this.screenData['field_248'],10)) ? 0 : parseInt(this.screenData['field_248'],10)) +
              (isNaN(parseInt(this.screenData['field_249'],10)) ? 0 : parseInt(this.screenData['field_249'],10)) +
              (isNaN(parseInt(this.screenData['field_250'],10)) ? 0 : parseInt(this.screenData['field_250'],10)) +
              (isNaN(parseInt(this.screenData['field_251'],10)) ? 0 : parseInt(this.screenData['field_251'],10)) +
              (isNaN(parseInt(this.screenData['field_252'],10)) ? 0 : parseInt(this.screenData['field_252'],10)) +
              (isNaN(parseInt(this.screenData['field_253'],10)) ? 0 : parseInt(this.screenData['field_253'],10)) +
              (isNaN(parseInt(this.screenData['field_254'],10)) ? 0 : parseInt(this.screenData['field_254'],10)) +
              (isNaN(parseInt(this.screenData['field_255'],10)) ? 0 : parseInt(this.screenData['field_255'],10)) +
              (isNaN(parseInt(this.screenData['field_256'],10)) ? 0 : parseInt(this.screenData['field_256'],10)) +
              (isNaN(parseInt(this.screenData['field_257'],10)) ? 0 : parseInt(this.screenData['field_257'],10)) +
              (isNaN(parseInt(this.screenData['field_260'],10)) ? 0 : parseInt(this.screenData['field_260'],10));

              if(sum < 1) this.values[i].groupFields[j].isError = true;
              else this.values[i].groupFields[j].isError = false;

            //2023/10/13追加
            } else if(noId == 289 && this.screenData['field_284_0'] == true) {
                  this.values[i].groupFields[j].isError = false;
                  //this.values[i].groupFields[j].error = '';

            //2023/10/13追加
            //} else if(noId == 289 && this.screenData['field_284_0'] != true && this.values[i].groupFields[j].length != 7) {
                  //this.values[i].groupFields[j].isError = true;
                  //this.values[i].groupFields[j].error = '7桁の数字を入力してください';

            } else if(noId == 269) {
              let beforeID = parseInt(noId,10) - 1;
              if(
                document.getElementById('field-'+noId) &&
                document.getElementById('field-'+noId).value.indexOf('野々市市') != -1 &&
                this.screenData['field_'+beforeID] &&
                this.screenData['field_'+beforeID] == '石川県'
              ) {
                this.values[i].groupFields[j].isError = false;
              } else if(!this.screenData['field_' + noId]) {
                this.values[i].groupFields[j].error = '入力してください';
                this.values[i].groupFields[j].isError = true;
              } else {
                this.values[i].groupFields[j].error = '野々市市の住所を入力してください';
                this.values[i].groupFields[j].isError = true;
              }

            } // 【アプリケーション固有の設定】ここまで
            else if (
              this.values[i].groupFields[j].type == 'Checkbox' &&
              this.values[i].groupFields[j].error != '' &&
              this.values[i].groupFields[j].error != '-' &&
              this.values[i].groupFields[j].required
            ) {
              // validate for Checkbox
              this.values[i].groupFields[j].isError = true;
              for (
                let k = 0;
                k < this.values[i].groupFields[j].desc.length;
                k++
              ) {
                if (
                  this.screenData[
                    'field_' + noId + '_' + k
                  ]
                ) {
                  this.values[i].groupFields[j].isError = false;
                }
              }
            } else if (this.values[i].groupFields[j].format.match(/^Number（([0-9]+)digits）$/)) {
              // Error check for NNdigits
              if (
                this.screenData['field_' + noId] &&
                this.screenData['field_' + noId].length == this.values[i].groupFields[j].format.match(/^Number（([0-9]+)digits）$/)[1]
              ) {
                this.values[i].groupFields[j].isError = false;
              } else {
                this.values[i].groupFields[j].isError = true;
              }
            } else if (this.values[i].groupFields[j].format == 'Number（Zipcode）') {
              // Error check for Zipcode
              if (
                this.screenData[
                  'field_' + noId
                ] &&
                this.screenData[
                  'field_' + noId
                ] != ''
              ) {
                if (
                  this.screenData['field_' + noId]
                    .length == 7
                ) {
                  this.values[i].groupFields[j].isError = false;
                } else {
                  this.values[i].groupFields[j].isError = true;
                }
              }
            } else if (
              this.values[i].groupFields[j].error != '' &&
              this.values[i].groupFields[j].error != '-' &&
              this.values[i].groupFields[j].required &&
              !this.screenData['field_' + noId]
            ) {
              // Error check for other requires
              if (this.values[i].groupFields[j].format == 'Text（Number input）') {
                // In case phone number separated into 3 parts
                if (
                  this.screenData['field_' + noId + '_' + 0] &&
                  this.screenData['field_' + noId + '_' + 0] != '' &&
                  this.screenData['field_' + noId + '_' + 1] &&
                  this.screenData['field_' + noId + '_' + 1] != '' &&
                  this.screenData['field_' + noId + '_' + 2] &&
                  this.screenData['field_' + noId + '_' + 2] != ''
                ) {
                  this.values[i].groupFields[j].isError = false;
                } else {
                  this.values[i].groupFields[j].isError = true;
                }
              } else {
                // Here comes for the item doesnt have value with error (column 12) without any other special validation.
                // We can set error because its required but blank
                this.values[i].groupFields[j].isError = true;
              }
            } else { // no error
              this.values[i].groupFields[j].isError = false;
            }
          }
        }
      }, 250);
    },

    //
    // UTILITIES
    //
    /**
     * When user input text number, allow number only, change numberJP to EN.
     */
    handleInputNumber: function (event, format, noId) {
      if (
        format == 'Number' ||
        format == 'Number（Natural）' ||
        format == 'Number（Zipcode）' ||
        format.match(/^Number（([0-9]+)digits）$/) ||
        format == 'Text（Number input）'
      ) {
        this.allowNumber(event);
        this.convertDoubleNumberToHalf(noId);
        if(format == 'Number（Natural）') this.convertNumberToInteger(noId);
      }
    },
    /**
     * Allow input number only
     * @param {object} event - data of input
     */
    allowNumber: function (event) {
      var charCode = event.which ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
      return true;
    },
    /**
     * Convert number JP to number EN
     */
    convertDoubleNumberToHalf: function (noId) {
      if (
        this.screenData['field_' + noId] != undefined &&
        this.screenData['field_' + noId] != ''
      ) {
        this.screenData['field_' + noId] = this.screenData[
          'field_' + noId
        ].replace(/[０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
        });
        this.screenData['field_' + noId] = this.screenData[
          'field_' + noId
        ].replace(/\D/g, '');
      }
    },
    /**
     * Convert number to natural number
     */
    convertNumberToInteger: function(noId) {
      if (
        this.screenData['field_' + noId] &&
        this.screenData['field_' + noId] != ''
      ) {
        if(!isNaN(this.screenData['field_' + noId])) this.screenData['field_' + noId] = parseInt(this.screenData['field_' + noId],10);
      }
    },
    /**
     * When user check on a checkbox it needs copy data to below form.
     * Index only can be 0 or 1.
     */
    copyData: function (noId, index, other) {
      if(this.screenData['field_'+noId+'_'+index] && other != '') {
        let copyRules = JSON.parse(other.replace(/__/g,','));
        if(copyRules[index] != undefined) {
          for(let key in copyRules[index]) {
            if(copyRules[index][key].indexOf('+') != -1) {
              let copies = copyRules[index][key].split('+');
              this.screenData['field_'+key] = '';
              for(let key2 in copies) {
                this.screenData['field_'+key] += this.screenData['field_'+copies[key2]] ? this.screenData['field_'+copies[key2]] : '';
              }
            } else {
              this.screenData['field_'+key] = this.screenData['field_'+copyRules[index][key]];
            }
          }
        }
      }
    },
    /**
     * Convert Katakana to Hiragana
     */
    convertKanaToHira: function(str) {
      return str.replace(/[\u30a1-\u30f6]/g, function(match) {
          var chr = match.charCodeAt(0) - 0x60;
          return String.fromCharCode(chr);
      });
    },
    /**
     * When user input kana textbox.
     */
    handleKana: function (label, noId, other) {
      if (label == '姓（かな）' || label == '姓（カナ）' ||
      label == '名（かな）' || label == '名（カナ）') {
        if(other.startsWith('Copy')) { // Because of autokana plugin, watcher doesnt work for the field binded autokana. We need to copy directly
          this.screenData['field_' + other.split('__')[1]] = this.screenData['field_'+noId];
        }
      }
    },
    /**
     * When user input kanji textbox.
     */
    handleKanji: function (label, noId, other) {
      if (label == '姓（漢字）' && (other == 'Katakana' || other == 'Hiragana')) {
        this.handleLastNameInput(noId, other);
      }
      if (label == '名（漢字）' && (other == 'Katakana' || other == 'Hiragana')) {
        this.handleFirstNameInput(noId, other);
      }
    },
    /** Handle for auto kana of last name */
    handleLastNameInput: function (noId, other) {
      let number = parseInt(noId,10);
      let number2 = number + 2;
      let fieldKana = 'field_' + number2;
      if (this.screenData['field_' + noId] != '') {
        this.screenData[fieldKana] = autokanaLast1[
          'kana' + number
        ].getFurigana();
      } else {
        this.screenData[fieldKana] = '';
      }

      if(other.startsWith('Copy')) { // Because of autokana plugin, watcher doesnt work for the field binded autokana. We need to copy directly
        this.screenData['field_' + other.split('__')[1]] = this.screenData['field_' + noId];
        this.screenData['field_' + (parseInt(other.split('__')[1],10) + 2)] = this.screenData[fieldKana];
      }
    },
    /** Handle for auto kana of first name */
    handleFirstNameInput: function (noId, other) {
      let number = parseInt(noId,10);
      let number2 = number + 2;
      let fieldKana = 'field_' + number2;

      if (this.screenData['field_' + noId] != '') {
        this.screenData[fieldKana] = autokanaFirst1[
          'kana' + number
        ].getFurigana();
      } else {
        this.screenData[fieldKana] = '';
      }

      if(other.startsWith('Copy')) { // Because of autokana plugin, watcher doesnt work for the field binded autokana. We need to copy directly
        this.screenData['field_' + other.split('__')[1]] = this.screenData['field_' + noId];
        this.screenData['field_' + (parseInt(other.split('__')[1],10) + 2)] = this.screenData[fieldKana];
      }
    },
    /**
     * Make list of day according to current selected month and year
     * @param {string} type - change year or month
     */
    daysInMonth: function (month, year) {
      return new Date(year, month, 0).getDate();
    },
    /**
     * find default
     */
    findDefault: function (format, inputExample) {
      if(format != '') {
        return '選択してください';
      }

      return inputExample[0];
    },
    /**
     * Find options
     */
    findOptions: function (format, desc, noId) {
      // find prefecture array
      if (format == 'Prefecture') {
        return [
          '選択してください',
          '北海道',
          '青森県',
          '岩手県',
          '宮城県',
          '秋田県',
          '山形県',
          '福島県',
          '茨城県',
          '栃木県',
          '群馬県',
          '埼玉県',
          '千葉県',
          '東京都',
          '神奈川県',
          '新潟県',
          '富山県',
          '石川県',
          '福井県',
          '山梨県',
          '長野県',
          '岐阜県',
          '静岡県',
          '愛知県',
          '三重県',
          '滋賀県',
          '京都府',
          '大阪府',
          '兵庫県',
          '奈良県',
          '和歌山県',
          '鳥取県',
          '島根県',
          '岡山県',
          '広島県',
          '山口県',
          '徳島県',
          '香川県',
          '愛媛県',
          '高知県',
          '福岡県',
          '佐賀県',
          '長崎県',
          '熊本県',
          '大分県',
          '宮崎県',
          '鹿児島県',
          '沖縄県',
        ];
      } else if (format == 'MM') {
        // find all mounths
        let mounths = ['選択してください'];
        for (let i = 1; i <= 12; i++) {
          mounths.push(String(i));
        }
        return mounths;
      } else if (format == 'DD') {
        // find all days
        let days = ['選択してください'];
        let monthNoId = parseInt(noId,10) - 1;
        let yearNoId = parseInt(noId,10) - 2;
        if (
          this.screenData['field_' + monthNoId] &&
          this.screenData['field_' + yearNoId]
        ) {
          let numberOfDays = this.daysInMonth(
            parseInt(this.screenData['field_' + monthNoId],10),
            parseInt(this.screenData['field_' + yearNoId],10)
          );
          for (let i = 1; i <= numberOfDays; i++) {
            days.push(String(i));
          }
        }
        return days;
      } else if (format == 'YYYY（元号YY）') {
        // find all years
        let years = [];
        years = this.findYearsJP();
        years.reverse();
        years.unshift('選択してください');
        return years;
      } else if (format == 'Grade') {
        let school = this.screenData['field_'+(parseInt(noId,10)-1)];
        if(school == '1．小学校') return ['選択してください','1','2','3','4','5','6'];
        else if(school == '2．中学校') return ['選択してください','1','2','3'];
      }

      return desc;
    },
    /**
     * Find list of years in JP
     */
    findYearsJP: function () {
      let years = [];
      let j = 0;
      let currentDate = new Date();
      let currentYear = parseInt(currentDate.getFullYear(),10);
      for (let i = 1900; i <= currentYear; i++) {
        let name = '';
        let year = '';
        if (i <= 1912) {
          if (i == 1900) {
            j = 33;
          }
          if (j < 10) {
            year = '0' + j;
          } else {
            year = j;
          }
          name = i + ' (明治 ' + year + '年)';
        }
        if (i > 1912 && i <= 1926) {
          if (i == 1913) {
            j = 2;
          }
          if (j < 10) {
            year = '0' + j;
          } else {
            year = j;
          }
          name = i + ' (大正 ' + year + '年)';
        }
        if (i > 1926 && i <= 1989) {
          if (i == 1927) {
            j = 2;
          }
          if (j < 10) {
            year = '0' + j;
          } else {
            year = j;
          }
          name = i + ' (昭和 ' + year + '年)';
        }
        if (i > 1989 && i <= 2019) {
          if (i == 1990) {
            j = 2;
          }
          if (j < 10) {
            year = '0' + j;
          } else {
            year = j;
          }
          name = i + ' (平成 ' + year + '年)';
        }

        if (i > 2019 && i <= currentYear) {
          if (i == 2020) {
            j = 2;
          }
          if (j < 10) {
            year = '0' + j;
          } else {
            year = j;
          }
          name = i + ' (令和 ' + year + '年)';
        }

        years.push({
          name: name,
          value: i,
        });

        j++;
      }
      return years;
    },
    /**
     * Auto fill data below of current postal code.
     */
    autoPostalCode: function (noId, format) {
      if (format == 'Number（Zipcode）') {
        let fieldRegion = parseInt(noId,10) + 1;
        let fieldLocality = parseInt(noId,10) + 2;
        if (
          this.screenData['field_' + noId] &&
          this.screenData['field_' + noId].length == 7
        ) {
          new window.YubinBango.Core(
            this.screenData['field_' + noId],
            (addr) => {
              if (addr.region) {
                this.screenData['field_' + fieldRegion] = addr.region; // 都道府県
              }
              if (addr.locality && addr.street) {
                this.screenData['field_' + fieldLocality] = addr.locality + addr.street; // 市区町村
              } else if (addr.locality) {
                this.screenData['field_' + fieldLocality] = addr.locality; // 市区町村
              }
              this.checkForm();
            }
          );
        }
      }
    },
    /**
     * for debug
     */
    debugShow: function() {
      console.log('[screenData]');
      console.log(this.screenData);
      console.log('[values]');
      console.log(this.values);
    },



    //
    // INITIALIZATION
    //
    /**
     * According to 'forms' build generate the forms.
     */
    init: async function () {
      this.nodeEnv = process.env.NODE_ENV;
      window.scrollTo(0, 0);
      this.forms = this.$store.state.selection;
      let csv1 = this.getCsv(1);
      let csv2 = this.getCsv(2);
      let csv3 = this.getCsv(3);
      let csv4 = this.getCsv(4);
      let csv5 = this.getCsv(5);
      let [data1, data2, data3, data4, data5] = await Promise.all([csv1, csv2, csv3, csv4, csv5]);
      this.values = data1.concat(this.forms.select2 ? data2 : [], this.forms.select3 ? data3 : [], this.forms.select4 ? data4 : [], this.forms.select5 ? data5 : []);
      this.csvValues = data1.concat(data2, data3, data4, data5);
      this.isFinishGetCSV = true;

      // datepickerの不可日（過去）を設定
      let date = new Date();
      date.setDate(date.getDate() - 1);
      this.datePickerDisabledDates.to = date;

      // 値初期化
      // Watcher用
      let noIds = [];
      this.values.map((item) => {
        noIds.push(...item.groupFields.map((groupField) => groupField.noId));
      });
      // autoKana用
      let arrKana = [];
      let arrHiragana = [];

      for (let i = 0; i < this.values.length; i++) {
        for (let j = 0; j < this.values[i].groupFields.length; j++) {
          // DateでReadonlyは当日を表示
          if(this.values[i].groupFields[j].type == 'Date'
          && this.values[i].groupFields[j].other.startsWith('Readonly')) {
            this.screenData['field_'+this.values[i].groupFields[j].noId] = new Date();
          }

          // ReadonlyのCheckboxはチェック済みステータスとする
          if(this.values[i].groupFields[j].type == 'Checkbox') {
            if(this.values[i].groupFields[j].other.startsWith('Readonly')) {
              this.screenData['field_'+this.values[i].groupFields[j].noId+'_0'] = true;
            } else {
              for(let k = 0;k < this.values[i].groupFields[j].desc.length; k++) {
                this.screenData['field_'+this.values[i].groupFields[j].noId+'_'+k] = false;
              }
            }
          }

          // カナ変換トリガーを追加
          if(this.values[i].groupFields[j].type.startsWith('Text')
          && this.values[i].groupFields[j].other == 'Katakana'
          && this.values[i].groupFields[j+1].other == 'Katakana' ) {
            let id = parseInt(this.values[i].groupFields[j].noId,10);
            arrKana.push(new Array(id,id+1,id+2,id+3));
          }
          if(this.values[i].groupFields[j].type.startsWith('Text')
          && this.values[i].groupFields[j].other == 'Hiragana'
          && this.values[i].groupFields[j+1].other == 'Hiragana' ) {
            let id = parseInt(this.values[i].groupFields[j].noId,10);
            arrHiragana.push(new Array(id,id+1,id+2,id+3));
          }

          // コピー処理対象にwatcherを追加する（コピー先が存在する場合のみ）
          if(this.values[i].groupFields[j].other.startsWith('Copy')) {
            if(noIds.includes(this.values[i].groupFields[j].other.split('__')[1])) {
              this.$watch('screenData.'+'field_'+this.values[i].groupFields[j].noId, function(newValue) {
                this.screenData['field_'+this.values[i].groupFields[j].other.split('__')[1]] = newValue;
              });
            }
          }
        }
      }

      // 実際のカナ変換トリガー追加処理
      setTimeout(() => {
        for (let i = 0; i < arrKana.length; i++) {
          this.screenData['field_' + arrKana[i][0]] = '';
          this.screenData['field_' + arrKana[i][1]] = '';
          this.screenData['field_' + arrKana[i][2]] = '';
          this.screenData['field_' + arrKana[i][3]] = '';
          if (document.getElementById('last-name-' + arrKana[i][0])) {
            autokanaLast1['kana' + arrKana[i][0]] = AutoKana.bind(
              '#last-name-' + arrKana[i][0],
              '#last-name-kana-' + arrKana[i][2],
              {
                katakana: true,
              }
            );
            autokanaFirst1['kana' + arrKana[i][1]] = AutoKana.bind(
              '#first-name-' + arrKana[i][1],
              '#first-name-kana-' + arrKana[i][3],
              {
                katakana: true,
              }
            );
          }
        }
        for (let i = 0; i < arrHiragana.length; i++) {
          this.screenData['field_' + arrHiragana[i][0]] = '';
          this.screenData['field_' + arrHiragana[i][1]] = '';
          this.screenData['field_' + arrHiragana[i][2]] = '';
          this.screenData['field_' + arrHiragana[i][3]] = '';
          if (document.getElementById('last-name-' + arrHiragana[i][0])) {
            autokanaLast1['kana' + arrHiragana[i][0]] = AutoKana.bind(
              '#last-name-' + arrHiragana[i][0],
              '#last-name-kana-' + arrHiragana[i][2]
            );
            autokanaFirst1['kana' + arrHiragana[i][1]] = AutoKana.bind(
              '#first-name-' + arrHiragana[i][1],
              '#first-name-kana-' + arrHiragana[i][3]
            );
          }
        }
      }, 500);

      this.checkForm();
    },
    /**
     * Get settings from CSV
     */
    getCsv: async function (name) {
      var temp = [];
      let response = await this.$http.get('./csv/data' + name + '.csv');
      const rows = response.body.split('\n');
      var groupFields = [];
      var dataRow = null;
      let id = 0;
      rows.forEach((row, index) => {
        const cols = row.split(',');
        if (cols[1].trim() != '') {
          if (groupFields.length) {
            dataRow.groupFields = groupFields;
            temp.push(dataRow);
          }
          id++;
          dataRow = {
            id: id,
            fieldID: name, // reference to fields array
            item: cols[1].trim(),
          };
          groupFields = [];
        }
        let desc = [];
        if (cols[8].trim() != '' && cols[8].trim() != '-') {
          desc = cols[8]
            .trim()
            .replace(/"|□|○|◎|・/g, '')
            .split('__');
        }
        let error = cols[11].trim().replace(/"|・/g, '');
        let inputExample = cols[9].trim().replace(/"/g, '').split('__-__');
        groupFields.push({
          noId: cols[0],
          id: index,
          displayConditions: cols[2].trim(),
          required: cols[3].trim() == '-' ? false : true,
          descUp: cols[4].trim(),
          label: cols[5].trim(),
          type: cols[6].trim(),
          format: cols[7].trim(),
          desc: desc,
          inputExample: inputExample,
          descDown: cols[10].trim(),
          error: error,
          isError: false,
          other: cols[12] ? cols[12].trim() : '',
        });

        if (rows.length == index + 1) {
          dataRow.groupFields = groupFields;
          temp.push(dataRow);
        }
      });

      return temp;
    },


    //
    // CONFIRM SECTION
    //
    /**
     * Show data for confirm page
     */
    showData: function (noId, desc, type, format, label) {
      this.isShowConfirm['field_'+noId] = true;

      // 【アプリケーション固有の設定】ここから
      if(noId == 108 || noId == 127 || noId == 146 || noId == 165 || noId == 184) {
        // 国籍は名前がある場合のみ指定とする
        let kanjiSei = parseInt(noId,10) - 11;
        if(!(this.screenData['field_'+kanjiSei] && this.screenData['field_'+kanjiSei] != '')) {
          this.isShowConfirm['field_'+noId] = false;
          return '';
        }
      }

      if(noId == 245) {
        // XXXのためYYYへ提出
        return (this.screenData['field_244'] && this.screenData['field_244'] != ''  ? this.screenData['field_244'] + 'から' : '') + (this.screenData['field_245'] && this.screenData['field_245'] != '' ? this.screenData['field_245'] + 'まで' : '');
      }
      // 【アプリケーション固有の設定】ここまで

      if(label == '生年月日（日）' ) {
        let fieldYear = parseInt(noId,10) - 2;
        let fieldMonth = parseInt(noId,10) - 1;
        let month = parseInt(this.screenData['field_'+fieldMonth],10) < 10 ? '0' + this.screenData['field_'+fieldMonth]: this.screenData['field_'+fieldMonth];
        let date = parseInt(this.screenData['field_'+noId],10) < 10 ? '0' + this.screenData['field_'+noId]: this.screenData['field_'+noId];
        // YYYY年MM月DD日
        if(this.screenData['field_'+fieldYear] && month && date) {
          return parseInt(this.screenData['field_'+fieldYear],10) + '年' + month + '月' + date + '日';
        }
      }

      if ((format == 'YYYY年M月D日' || format == 'YYYY年M月D日（Future）')
      && this.screenData['field_' + noId]) {
        let newDate = new Date(this.screenData['field_' + noId]);
        let currentMonth = newDate.getMonth() + 1;
        let currentDate = newDate.getDate();
        let currentMonth2 =
          currentMonth < 10 ? '0' + currentMonth : currentMonth;
        let currentDate2 = currentDate < 10 ? '0' + currentDate : currentDate;
        return (
          newDate.getFullYear() +
          '年' +
          currentMonth2 +
          '月' +
          currentDate2 +
          '日'
        );
      }

      if (type == 'Checkbox') {
        let html = [];
        desc.forEach((item, index) => {
          if (
            this.screenData['field_' + noId + '_' + index] != undefined &&
            this.screenData['field_' + noId + '_' + index] == true
          ) {
            if(item == 'その他') {
              let otherId = parseInt(noId,10) + 1;
              html.push(this.screenData['field_' + otherId]);
            } else {
              html.push(item);
            }
          }
        });
        return html.join(',');
      }

      if (format == 'Text（Number input）') {
        return (
          this.screenData['field_' + noId + '_' + '0'] + '-' +
          this.screenData['field_' + noId + '_' + '1'] + '-' +
          this.screenData['field_' + noId + '_' + '2']
        );
      }

      if(!this.screenData['field_' + noId]) {
        this.isShowConfirm['field_'+noId] = false;
        return '';
      }

      return this.screenData['field_' + noId];
    },
  },
};
</script>

<style scoped>
.debug {
  display:none;
}
.debug.is-development {
  display:block;
  margin-top: 20px;
}
.debug.is-development+.row {
  margin-top: 0;
}
</style>
