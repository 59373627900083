<template>
  <div
    v-click-outside="outside"
    class="custom-select"
    :class="{ open: open }"
    :tabindex="tabindex"
    @blur="open = false"
  >
    <div
      v-if="selected != ''"
      class="selected"
      :class="{ open: open }"
      @click="open = !open"
    >
      {{ selected }}
    </div>
    <div
      v-if="selected == ''"
      class="placeholder"
      :class="{ open: open }"
      @click="open = !open"
    >
      {{ textDefault }}
    </div>
    <div
      class="items"
      :class="{ 'select-hide': !open }"
    >
      <div
        v-for="(option, i) of options"
        :key="i"
        class="item-option"
        @click="changeValue(option);"
        v-html="findOption(option)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    textDefault: {
      type: String,
      required: false,
      default: '',
    },
    data: {
      type: String,
      required: false,
      default: '',
    },
    selectedVal: {
      type: String,
      required: false,
      default: '',
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: '',
      open: false,
    };
  },
  watch: {
    data: function () {
      this.selected = this.data;
    },
  },
  mounted() {
    if (this.selectedVal != '') {
      this.selected = this.selectedVal;
    }
    this.$emit('input', this.selected);
    this.$emit('change');
  },
  methods: {
    findOption: function (option) {
      if (option.name) {
        return option.name;
      } else {
        return option;
      }
    },
    changeValue: function (val) {
      let newName = '';
      if (val.name) {
        newName = val.name == this.textDefault ? '' : val.name;
      } else {
        newName = val == this.textDefault ? '' : val;
      }
      this.selected = newName;
      this.open = false;
      this.$emit('input', newName);
      this.$emit('change');
    },
    outside: function () {
      var self = this;
      self.open = false;
    },
  },
};
</script>